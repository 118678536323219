<template>
  <div>
    <span class="label">
      {{ label }}
    </span>
    <el-autocomplete
      :disabled="disabled"
      style="width: 100%"
      size="mini"
      v-model="model"
      :placeholder="placeholder"
      :fetch-suggestions="search"
      :trigger-on-focus="false"
      value-key="flat"
      @focus="onFocus"
      @change="$emit('input', resultedValue ? resultedValue.flat : null); closePopup()"
      @select="$emit('input', resultedValue ? resultedValue.flat : null); closePopup()"
    >
      <template slot-scope="{ item }">
        {{ item.flat }}
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'flat-field',
  inject: ['getPreviousValue'],

  props: {
    label: {
      type: String
    },
    type: {
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: String
    },
    selectedAddress: {
      type: Object
    },
    addressObjectsValue: {
      type: Object
    },
    addressType: {
      type: String,
      default: 'administrative'
    }
  },

  data () {
    return {
      model: null,
      options: [],
      isLoaded: false
    }
  },

  computed: {
    disabled () {
      return !(this.previousValue)
    },
    previousValue () {
      return this.getPreviousValue('flat')
    },
    resultedValue () {
      if (!this.model) {
        return null
      }

      let option = this.options.find(item => item.flat === this.model) || {}
      option.flat = this.model

      return option
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        this.model = value
        this.$emit('input', this.resultedValue?.flat || null)
        this.$emit('flat-change', this.resultedValue)
      }
    }
  },

  methods: {
    async load () { },
    onFocus () {
      this.load()
    },
    async search (query, callback) {
      let results = query ? this.options.filter(item => item.flat.toLowerCase().indexOf(query.toLowerCase()) === 0) : this.options
      callback(results)
      results.length > 0 && this.$emit('open-popup')
    },
    closePopup () {
      // Задержка из-за v-clickoutside в AddressObjects
      setTimeout(() => this.$emit('close-popup'), 200)
    }
  }
}
</script>

<style scoped>
.label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #7D7D7D;
  display: block;
  padding: 5px 5px 0px 5px;
}
</style>
